import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import AdminMenu from "../../components/Layout/AdminMenu";
import axios from "axios";
import moment from "moment";
import "./../../styles/Users.css";
import { API_BASE_URL } from "./../../config";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchUsersAndOrders = async () => {
      try {
        const usersResponse = await axios.get(
          `${API_BASE_URL}/api/v1/auth/all-users`
        );
        const usersData = usersResponse.data;

        const ordersResponse = await axios.get(
          `${API_BASE_URL}/api/v1/auth/all-orders`
        );
        const ordersData = ordersResponse.data;

        setUsers(usersData.filter((user) => user.role !== 1)); // Filter out admin users
        setOrders(ordersData);
      } catch (error) {
        console.error("Error fetching users or orders:", error);
      }
    };

    fetchUsersAndOrders();
  }, []);

  const getRecentOrders = (userId) => {
    const userOrders = orders.filter((order) => order.buyer._id === userId);
    return userOrders.length ? userOrders.slice(0, 3) : null;
  };

  const getNotDeliveredOrdersCount = (userId) => {
    const userOrders = orders.filter(
      (order) => order.buyer._id === userId && order.status !== "Delivered"
    );
    return userOrders.length;
  };

  return (
    <Layout title="Dashboard - All Users">
      <div className="row dashboard">
        <div className="col-md-3">
          <AdminMenu />
        </div>
        <div className="col-md-9">
          <h1 className="text-center">All Users</h1>
          {users?.map((user, index) => (
            <div className="border shadow user-card" key={user._id}>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Mobile</th>
                    <th scope="col">Total Orders</th>
                    <th scope="col">Not Delivered</th>{" "}
                    {/* New column for not delivered orders */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{index + 1}</td>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.phone}</td>
                    <td>{getRecentOrders(user._id)?.length || 0}</td>
                    <td>{getNotDeliveredOrdersCount(user._id)}</td>{" "}
                    {/* Display not delivered orders count */}
                  </tr>
                </tbody>
              </table>
              <div className="container">
                <h6>Recent Orders:</h6>
                <ul>
                  {getRecentOrders(user._id) ? (
                    getRecentOrders(user._id).map((order, i) => (
                      <li key={order._id}>
                        Order {i + 1}: {order.status} -{" "}
                        {moment(order.createdAt).fromNow()}
                      </li>
                    ))
                  ) : (
                    <li>No recent orders.</li>
                  )}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Users;
