import React from "react";
import { NavLink } from "react-router-dom";
import "./../../styles/Footer.css"; // Import the CSS file

const handleClick = (e) => {
  e.preventDefault();
  window.open(
    "https://www.paypal.com/in/webapps/mpp/paypal-popup",
    "WIPaypal",
    "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700"
  );
};

const Footer = () => {
  return (
    <div className="footer">
      <p className="text-center mt-3">
        <NavLink to="/about" className="navlink">
          About
        </NavLink>
        <span className="separator">|</span>
        <NavLink to="/contact" className="navlink">
          Contact
        </NavLink>
        <span className="separator">|</span>
        <NavLink to="/policy" className="navlink">
          Privacy Policy
        </NavLink>
      </p>
      <div className="badges">
        <a
          href="https://www.paypal.com/in/webapps/mpp/paypal-popup"
          title="How PayPal Works"
          onClick={handleClick}
        >
          {" "}
          <img
            src="https://www.paypalobjects.com/webstatic/mktg/logo/bdg_payments_by_pp_2line.png"
            alt="Check out with PayPal"
          />
        </a>{" "}
        <a name="trustbadge" href="\">
          {" "}
          <img
            name="trustseal"
            alt="Trust Badges"
            src="https://trustlock.co/wp-content/uploads/2019/01/ssl-secure-checkout-trust-seal-free.png"
            width="171"
            height="75"
          />
        </a>{" "}
        <a
          href="//www.dmca.com/Protection/Status.aspx?ID=c079616b-07ef-4648-9963-b5d4050e5480"
          title="DMCA.com Protection Status"
          className="dmca-badge"
        >
          {" "}
          <img
            src="https://images.dmca.com/Badges/dmca-badge-w100-2x1-03.png?ID=c079616b-07ef-4648-9963-b5d4050e5480"
            alt="DMCA.com Protection Status"
          />
        </a>{" "}
        <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js">
          {" "}
        </script>
        <a name="trustbadge" href="\">
          {" "}
          <img
            name="trustseal"
            alt="Trust Badges"
            src="https://trustlock.co/wp-content/uploads/2019/01/satisisfaction-guaranteed-badge-iamge-icon-badge.png"
            width="171"
            height="75"
          />
        </a>{" "}
        <a name="trustbadge" href="\">
          {" "}
          <img
            name="trustseal"
            alt="Trust Badges"
            src="https://trustlock.co/wp-content/uploads/2019/01/BEST-PRICE-GUARANTEED-free-trust-badge.png"
            width="171"
            height="75"
          />
        </a>
      </div>
    </div>
  );
};

export default Footer;
