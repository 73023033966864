import React from "react";
import Layout from "./../components/Layout/Layout";
import { BiMailSend, BiPhoneCall, BiCurrentLocation } from "react-icons/bi";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <Layout
      title="Contact us - LikesLelo"
      description="Reach us anytime 24x7"
      keywords="instagram how to get more followers,1k followers on instagram free,generate likes on Instagram,pay instagram for followers,instagram likes and followers,instagram followers increase website,facebook followers free,ig likes and followers,site to increase instagram followers,free fb followers,followers instagram likes,facebook followers,increase followers,fb followers,grow instagram followers,increase facebook followers,1000 followers on Instagram,facebook likes free,fb followers increase,follow for more,1000 ig followers,gain followers on instagram fast,free fb likes,buy a follower,followers fast Instagram,followers 1000,ig followers fast,get instagram followers free,1k followers on Instagram,followers up,like increase for Instagram,instagram followers india,instagram followers link,more followers on instagram free,get ig followers free,free likes and followers on Instagram,instagram free followers website,instagram free followers increase,purchase instagram views,instagram free followers link,followers tools for Instagram,free followers site,free followers website,tool for instagram followers,cheap instagram followers,more instagram followers,get more instagram followers,instagram panel,cheap ig followers,smm panel Instagram,get more ig followers,increase facebook likes,need more followers on Instagram,smm instagram panel,new followers,app that boost instagram followers,fb like increase,generate more followers on Instagram,use viral,real instagram likes,followers buy,instagram follower growth,instagram followers panel,real ig likes,growth followers Instagram,ig followers site,followers panel Instagram,instagram follow panel,followers instagram growth,free 1000 followers,instagram post likes free,instagram like increase free"
    >
      <div className="row contactus ">
        <div className="col-md-6">
          <img
            src="/images/contactus.webp"
            alt="contactus"
            align="center"
            style={{ height: "250px", width: "90%" }}
          />
        </div>
        <div className="col-md-5 montserrat-font">
          <h2 className="bg-dark p-1 text-white text-center ">CONTACT US</h2>
          <p className="text-justify mt-1">
            For any query and info about our services and pricing, feel free to
            reach us anytime at:
          </p>
          <p className="mt-1">
            <BiMailSend /> : www.help@likeslelo.com
          </p>
          <p className="mt-1">
            <BiPhoneCall /> : 012-3456789
          </p>
          <p className="mt-1">
            <BiPhoneCall /> : 012-9876543
          </p>
          <p className="mt-1">or, pay us a visit at:</p>
          <p>
            <BiCurrentLocation /> :{" "}
            <Link to="https://maps.app.goo.gl/8SWXCWPYSauA5ziS9">
              Likes-Lelo Limited
            </Link>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
