import React from "react";
import Layout from "./../components/Layout/Layout.js";

const About = () => {
  return (
    <Layout
      title="About us - LikesLelo"
      instadescription="Brief Introduction to Likes-Lelo"
      keywords="instagram followers Instagram,get likes on instagram free,get followers,likes for ig,get a follower,instagram likes for likes,followers real,facebook likes,get free followers,indian followers,social following,boost instagram likes,free real instagram followers,likes boost Instagram,pay for instagram followers,get instagram likes,pay for ig followers,purchase instagram likes,get ig likes,instagram followers website,,smm followers,smm panel instagram indian followers,increase instagram views,instagram followers likes twitter followers purchase, instagram followers online, like followers, 1k ig followers, instagram followers quick, followers website, go smm, free followers increase, instagram followers free link, social viral, get real instagram followers, instagram post likes, get real ig followers, get instagram views, instagram tools free, generate instagram followers, 50k instagram followers, ig panel free followers, free followers and likes, instagram count, free real instagram likes, follower and like, increase likes, pay for instagram likes, add followers instagram, purchase facebook followers, social followers, smm panel instagram followers, smm world, followers panel, free smm panel, facebook page followers increase, non drop followers, pay for ig likes, purchase facebook likes, best site for instagram followers, add ig followers, best website for instagram followerss"
    >
      <div className="row aboutus">
        <div className="col-md-6 d-flex justify-content-center align-items-center">
          <img
            src="/images/about.webp"
            alt="aboutus"
            style={{ width: "100%", height: "auto", maxHeight: "100%" }}
          />
        </div>
        <div className="col-md-6 text-container">
          <p className="text-justify mt-2 font-bold montserrat-font">
            Likes-Lelo has been established with the mission to provide
            professional social media marketing services at an affordable price
            for small businesses and website owners. We serve clients worldwide,
            ensuring that they receive the highest quality service tailored to
            their specific needs.
            <br />
            <br />
            Customer satisfaction is our strongest asset, and we pride ourselves
            on our commitment to excellence. Join us and experience the
            difference that personalized, professional social media marketing
            can make for your business.
            <br />
            <br />
            At Likes-Lelo, we understand the unique challenges that small
            businesses and website owners face in today's digital landscape.
            That's why we offer a range of customizable social media marketing
            packages designed to meet the diverse needs of our clients. Whether
            you're looking to increase brand awareness, drive traffic to your
            website, or boost your social media engagement, our team of
            experienced professionals is here to help you achieve your goals.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default About;
