import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import AdminMenu from "../../components/Layout/AdminMenu";
import toast from "react-hot-toast";
import axios from "axios";
import { API_BASE_URL } from "../../config";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Use snow theme CSS
import "./../../styles/CreateBlog.css"; // Ensure your custom CSS is imported

const CustomToolbar = ({ toolbarId }) => (
  <div id={toolbarId}>
    <select className="ql-font">
      <option value="arial">Arial</option>
      <option value="comic-sans">Comic Sans</option>
      <option value="courier-new">Courier New</option>
      <option value="georgia">Georgia</option>
      <option value="helvetica">Helvetica</option>
      <option value="lucida">Lucida</option>
      <option value="times-new-roman">Times New Roman</option>
      <option value="verdana">Verdana</option>
      <option value="tahoma">Tahoma</option>
      <option value="trebuchet-ms">Trebuchet MS</option>
      <option value="gill-sans">Gill Sans</option>
      <option value="impact">Impact</option>
      <option value="arial-black">Arial Black</option>
      <option value="palatino">Palatino</option>
      <option value="garamond">Garamond</option>
      <option value="bookman">Bookman</option>
      <option value="comic-sans-ms">Comic Sans MS</option>
      <option value="candara">Candara</option>
      <option value="consolas">Consolas</option>
      <option value="playfair-display">Playfair Display</option>
    </select>
    <select className="ql-size">
      <option value="9px">9px</option>
      <option value="10px">10px</option>
      <option value="11px">11px</option>
      <option value="12px">12px</option>
      <option value="14px">14px</option>
      <option value="16px">16px</option>
      <option value="18px">18px</option>
      <option value="20px">20px</option>
      <option value="22px">22px</option>
      <option value="24px">24px</option>
      <option value="26px">26px</option>
      <option value="28px">28px</option>
    </select>
    <select className="ql-header">
      <option value="1">Heading 1</option>
      <option value="2">Heading 2</option>
      <option value="3">Heading 3</option>
      <option value="4">Heading 4</option>
      <option value="5">Heading 5</option>
      <option value="6">Heading 6</option>
      <option value="">Normal</option>
    </select>
    <button className="ql-list" value="ordered"></button>
    <button className="ql-list" value="bullet"></button>
    <button className="ql-bold"></button>
    <button className="ql-italic"></button>
    <button className="ql-underline"></button>
    <button className="ql-strike"></button>
    <button className="ql-blockquote"></button>
    <button className="ql-indent" value="-1"></button>
    <button className="ql-indent" value="+1"></button>
    <select className="ql-align">
      <option value=""></option>
      <option value="center"></option>
      <option value="right"></option>
      <option value="justify"></option>
    </select>
    <select className="ql-color"></select>
    <select className="ql-background"></select>
    <button className="ql-link"></button>
    <button className="ql-script" value="sub"></button>
    <button className="ql-script" value="super"></button>
    <button className="ql-code-block"></button>
    <button className="ql-clean"></button>
  </div>
);

// Custom font size and fonts
const Font = ReactQuill.Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida",
  "times-new-roman",
  "verdana",
  "tahoma",
  "trebuchet-ms",
  "gill-sans",
  "impact",
  "arial-black",
  "palatino",
  "garamond",
  "bookman",
  "comic-sans-ms",
  "candara",
  "consolas",
  "playfair-display",
];
ReactQuill.Quill.register(Font, true);

const Size = ReactQuill.Quill.import("attributors/style/size");
Size.whitelist = [
  "9px",
  "10px",
  "11px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
  "26px",
  "28px",
];
ReactQuill.Quill.register(Size, true);

const CreateBlog = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${API_BASE_URL}/api/v1/blogs/create-blog`,
        {
          title,
          content,
        }
      );
      if (data.success) {
        toast.success("Blog created successfully");
        setTitle("");
        setContent("");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  return (
    <Layout title="Dashboard - Create Blog">
      <div className="container-fluid m-3 p-3 dashboard">
        <div className="row">
          <div className="col-md-3">
            <AdminMenu />
          </div>
          <div className="col-md-8">
            <div className="create-blog">
              <h1>Create Blog</h1>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="title">Title</label>
                  <CustomToolbar toolbarId="toolbar-title" />
                  <ReactQuill
                    value={title}
                    onChange={(value) => setTitle(value)}
                    modules={{ toolbar: { container: "#toolbar-title" } }}
                    formats={CreateBlog.formats}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="content">Content</label>
                  <CustomToolbar toolbarId="toolbar-content" />
                  <ReactQuill
                    value={content}
                    onChange={(value) => setContent(value)}
                    modules={{ toolbar: { container: "#toolbar-content" } }}
                    formats={CreateBlog.formats}
                  />
                </div>
                <button type="submit" className="btn btn-primary">
                  Create Blog
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

// ReactQuill formats configuration
CreateBlog.formats = [
  "font",
  "size",
  "header",
  "list",
  "bullet",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "indent",
  "align",
  "color",
  "background",
  "link",
  "script",
  "code-block",
];

export default CreateBlog;
