import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Layout from "./../components/Layout/Layout";
import "../styles/Homepage.css";
import { API_BASE_URL } from "./../config";

const testimonials = [
  {
    id: 1,
    text: "This service boosted my social media presence tremendously! \nThe increase in likes and followers was immediate. \nHighly recommend to anyone looking to grow their online influence.",
    author: "Rajesh Kumar",
  },
  {
    id: 2,
    text: "I saw immediate results. \nThe team was very professional and the process was seamless. \nWould definitely use this service again!",
    author: "Anjali Sharma",
  },
  {
    id: 3,
    text: "Great value for money. \nThe customer service was excellent and my follower count doubled in just a week. \nVery satisfied!",
    author: "Sanjay Singh",
  },
  {
    id: 4,
    text: "My follower count doubled in a week. \nThis is the best service for social media growth I have ever used. \nAmazing!",
    author: "Priya Patel",
  },
  {
    id: 5,
    text: "Professional and efficient. \nThe results were quick and exactly what I needed. \nWill use again.",
    author: "Vikram Gupta",
  },
  {
    id: 6,
    text: "Helped me reach a wider audience quickly. \nI can now focus on creating content while they handle the engagement. \nFantastic service!",
    author: "Nina Mehta",
  },
  {
    id: 7,
    text: "The service was amazing! \nI got the engagement I was looking for almost instantly. \nHighly recommended.",
    author: "Aarav Verma",
  },
  {
    id: 8,
    text: "Very happy with the results. \nMy posts are now getting the attention they deserve. \nThank you!",
    author: "Meera Joshi",
  },
  {
    id: 9,
    text: "Excellent service with great results. \nThe team is very supportive and the process is easy. \nWill use it again for sure!",
    author: "Nikhil Kumar",
  },
  {
    id: 10,
    text: "Amazing experience! \nThe likes and followers I gained have really helped boost my brand. \nHighly recommend.",
    author: "Kavya Rao",
  },
];

const HomePage = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [currentTestimonials, setCurrentTestimonials] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(true);

  useEffect(() => {
    getAllCategory();
    updateTestimonials();
    const interval = setInterval(updateTestimonials, 5000); // Update every 5 seconds
    return () => clearInterval(interval);
  }, []);

  const getAllCategory = async () => {
    try {
      const { data } = await axios.get(
        `${API_BASE_URL}/api/v1/category/get-category`
      );
      if (data?.success) {
        setCategories(data?.category);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCategoriesLoading(false);
    }
  };

  const updateTestimonials = () => {
    const shuffled = testimonials.sort(() => 0.5 - Math.random());
    setCurrentTestimonials(
      shuffled.slice(
        0,
        window.innerWidth < 576 ? 2 : window.innerWidth < 768 ? 3 : 4
      )
    );
  };

  useEffect(() => {
    const handleResize = () => {
      updateTestimonials();
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Layout
      title="Home - LikesLelo"
      description="Welcome to Likes-Lelo. Your one-stop destination for boosting your social media profiles. Get your profiles boosted instantly. No.1 in Social Media Marketing"
      keyword="free instagram followers free, instagram followers free, ig followers free, instagram fol, free followers, free followers free, ig followers, instagram views, instagram how to increase followers, instagram likes, instagram and likes, instagram likes free, ig likes free, get instagram followers, get ig followers, insta get followers, increase instagram followers, boost instagram followers, increase ig followers, gain followers ig, insta followers boost, increase in instagram followers, get followers instagram for free, purchase instagram followers, purchase ig followers, instagram like increase, buy follow Instagram, follow instagram buy, buy instagram follow, free likes, followers Instagram, boost instagram followers free, increase instagram followers for free, website to gain followers on instagram free, boost ig followers free, real instagram followers, real ig followers, like 4 like, followers india, followers for followers Instagram, instagram and followers, instagram followers Instagram, get likes on instagram free, get followers, likes for ig, get a follower, instagram likes for likes, followers real, facebook likes, get free followers, indian followers, social following, boost instagram likes, free real instagram followers, likes boost Instagram, pay for instagram followers, get instagram likes, pay for ig followers, purchase instagram likes, get ig likes, instagram followers website"
    >
      {/* Category Buttons */}
      <div className="category-buttons">
        {categoriesLoading ? (
          <div className="loading-container">
            <div className="loading-spinner"></div>
            <p className="loading-text">Loading Categories... Please wait</p>
          </div>
        ) : (
          categories.map((category) => (
            <button
              key={category._id}
              className="btn btn-primary m-2"
              onClick={() => navigate(`/category/${category.slug}`)}
            >
              {category.name}
            </button>
          ))
        )}
      </div>

      {/* Banner Image */}
      <div className="banner-image">
        <img
          src="/images/banner.webp"
          className="banner-img"
          alt="bannerimage"
          width={"100%"}
        />
      </div>

      {/* Why Us Section */}
      <div className="why-us">
        <h2>Why Us?</h2>
        <div className="why-us-container">
          <div className="why-us-text p-2 col-md-7">
            <p>
              We specialize in providing paid social media likes to amplify your
              online presence. Leveraging the vast reach of social media, we
              help you promote your work through a significant increase in likes
              and comments. Our dedicated team of researchers and developers
              delivers authentic paid likes to take your business to new
              heights. We prioritize teamwork and ensure quick, secure results.
            </p>
            <p>
              <strong>Enhance Visibility:</strong> Increased engagement improves
              your posts' algorithm ranking, expanding your reach.
              <br />
              <br />
              <strong>Accelerate Growth:</strong> Quick initial boost helps you
              grow your follower base faster.
              <br />
              <br />
              <strong>Save Time and Effort:</strong> Focus on creating quality
              content while paid likes handle engagement.
              <br />
              <br />
              <strong>Competitive Edge:</strong> Stand out in your industry with
              higher engagement metrics.
              <br />
              <br />
              <strong>Quick Results:</strong> Achieve immediate boosts for
              time-sensitive campaigns.
            </p>
          </div>
          <div className="why-us-image col-md-3">
            <img
              src="/images/banner2.webp"
              className="anim-up-down"
              alt="Why Us"
              width={"100%"}
            />
          </div>
        </div>

        {/* Testimonials Section */}
        <div className="testimonials-section">
          <h2>Testimonials</h2>
          <div className="testimonials">
            {currentTestimonials.map((testimonial) => (
              <div key={testimonial.id} className="testimonial">
                <p>"{testimonial.text}"</p>
                <p>
                  <strong>- {testimonial.author}</strong>
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HomePage;
